import React from 'react';

import './Footer.scss';

const Footer = () => {
  return (
    <div className='pie-pagina'>
        <p>Editorial © 2025</p>
    </div>
  )
}

export default Footer